/**
 *
 * @param {string} name 名称
 * @param {number} num 数量
 * @returns 新增QBI菜单占位坑
 */
const _url = () => import(`../views/dataModules/dataAnalysis/Index`);
export const newDataLisFunc = (name, num) => {
    const newRouteList = {};
    for (let i = 1; i <= num; i++) {
        ; (n => {
            newRouteList[`${name}Analysis${n}`] = _url;
        })(i);
    }
    return newRouteList;
};
