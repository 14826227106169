import { newDataLisFunc } from './dataList';
const contractModules = {
    tianfuBankContract: () => import(`../views/contractModules/tianfuBankContract/Index`),
    contractManage: () => import(`../views/contractModules/contractManage/Index`),
    accountManage: () => import(`../views/customerModules/accountManage/Index`),
    contractGroupManage: () => import(`../views/contractModules/contractGroupManage/Index`),
    contractView: () => import(`../views/contractModules/contractView/Index`),
    contractArchive: () => import(`../views/contractModules/contractArchive/Index`),
    contractRegistration: () => import(`../views/contractModules/contractRegistration/Index`),
    companyManagerArchive: () => import(`../views/basicSettings/companyManagerArchive/Index`),
    industryList: () => import(`../views/basicSettings/industryList/Index`),
    opportunity: () => import(`../views/opportunityModules/opportunity/Index`),
    flowAbandom: () => import(`../views/basicSettings/flowAbandom/Index`),
    ...newDataLisFunc(`contract`, 10)
};
const customerModules = {
    customer: () => import(`../views/customerModules/customer/Index`),
    customerSeries: () => import(`../views/customerModules/customerSeries/Index`),
    customerAuth: () => import(`../views/customerModules/customerAuth/Index`),
    customerPersonResponsible: () => import(`../views/customerModules/customerPersonResponsible/Index`),
    customerGroup: () => import(`../views/customerModules/customerGroup/Index`),
    ...newDataLisFunc(`customer`, 10)
};
const dataModules = {
    ...newDataLisFunc(`data`, 10)
};
export default {
    ...contractModules,
    ...customerModules,
    ...dataModules
};
